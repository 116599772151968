function objectWithoutProperties (obj, exclude) { var target = {}; for (var k in obj) if (Object.prototype.hasOwnProperty.call(obj, k) && exclude.indexOf(k) === -1) target[k] = obj[k]; return target; }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.controller.isLoading)?_c('LoadingOverlay'):_vm._e(),_c('div',{staticClass:"wrapper-form-approving-poin"},[_c('h2',{staticClass:"title-approving-poin"},[_vm._v("Form Request Poin")]),_c('div',{staticClass:"body-approving-poin"},[_c('AdjustmentTextInput',{attrs:{"fieldLabel":"Masukkan nominal poin","type":"currency","placeholder":"Nominal Poin","showPrefix":false,"allowNullNumber":true,"disabled":true},model:{value:(_vm.form.poin),callback:function ($$v) {_vm.$set(_vm.form, "poin", $$v)},expression:"form.poin"}}),_c('div',{staticClass:"mb-3"},[_vm._m(0),_c('div',{staticClass:"flex gap-x-12 mt-3"},[_c('RadioButton',{attrs:{"customClassLabel":'font-normal text-sm text-black ml-3',"label":"Penambahan","name":_vm.EnumTransactionParcelPoinRequest.CREDIT,"inputValue":_vm.EnumTransactionParcelPoinRequest.CREDIT,"isDisabled":true},model:{value:(_vm.form.transaction),callback:function ($$v) {_vm.$set(_vm.form, "transaction", $$v)},expression:"form.transaction"}}),_c('RadioButton',{attrs:{"customClassLabel":'font-normal text-sm text-black ml-3',"label":"Pengurangan","name":_vm.EnumTransactionParcelPoinRequest.DEBIT,"inputValue":_vm.EnumTransactionParcelPoinRequest.DEBIT,"isDisabled":true},model:{value:(_vm.form.transaction),callback:function ($$v) {_vm.$set(_vm.form, "transaction", $$v)},expression:"form.transaction"}})],1)]),_c('AdjustmentTextInput',{attrs:{"type":"textarea","fieldLabel":"Notes","disabled":true,"placeholder":"Masukkan catatan kepentingan request poin"},model:{value:(_vm.form.note),callback:function ($$v) {_vm.$set(_vm.form, "note", $$v)},expression:"form.note"}}),_c('div',{staticClass:"wrapper-button-reject"},[_c('Button',{staticClass:"button-reject",attrs:{"data-testid":"adjustment-approving-parcel-poin-detail__button-reject","buttonStyle":"outline","customPadding":true,"disabled":_vm.$v.form.$invalid},on:{"click":function () {
              _vm.modalReject = true
            }}},[_vm._v("Reject")]),_c('Button',{staticClass:"button-reject",attrs:{"data-testid":"adjustment-approving-parcel-poin-detail__button-approve","customPadding":true,"disabled":_vm.$v.form.$invalid},on:{"click":function () {
              _vm.modalConfirm = true
            }}},[_vm._v("Approve")])],1)],1),(_vm.dataHistoryUser.length > 0)?_c('div',{staticClass:"wrapper-table-approving-poin"},[_c('div',{staticClass:"section-title"},[_c('span',{staticClass:"text-user-activity"},[_vm._v("History log poin pengguna ("+_vm._s(_vm.phoneNumberUser)+")")]),_c('span',{staticClass:"button-show-hide",attrs:{"data-testid":"adjustment-approving-parcel-poin-detail__button-show-hide"},on:{"click":_vm.toggleTableShow}},[_vm._v(" "+_vm._s(_vm.isShowTable ? 'Hide' : 'Open'))])]),(_vm.isShowTable)?_c('div',{staticClass:"table-approving-poin"},[_c('DataTableV2',{attrs:{"isLoading":false,"headers":_vm.headers,"dataItems":_vm.dataHistoryUser},scopedSlots:_vm._u([{key:"4",fn:function(ref){
            var rest = objectWithoutProperties( ref, [] );
            var props = rest;
return [(props.data.value)?_c('div',{staticClass:"wrapper-notes"},[_c('span',{staticClass:"notes-text"},[_vm._v(_vm._s(props.data.value))]),_c('span',{staticClass:"notes-button",attrs:{"data-testid":("adjustment-approving-parcel-poin-detail__button-view-detail-" + (props.index))},on:{"click":function($event){return _vm.seeNotes(props.index)}}},[_vm._v("View detail")])]):_vm._e()]}}],null,false,844066410)}),_c('PaginationNav',{attrs:{"page":_vm.parameters.page,"perPage":_vm.parameters.perPage,"totalItem":_vm.controller.paginationData.totalItem},on:{"input":function () {
              // fetch history
              _vm.fetchHistory()
            },"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return (function () {
              // fetch history
              _vm.fetchHistory()
            })($event)}},model:{value:(_vm.parameters.page),callback:function ($$v) {_vm.$set(_vm.parameters, "page", $$v)},expression:"parameters.page"}})],1):_vm._e()]):_vm._e()]),_c('ModalReject',{attrs:{"data-testid":"adjustment-approving-parcel-poin-detail__modal-reject","visible":_vm.modalReject,"title":_vm.MODAL_REJECT_REQUEST_POIN.title,"subtitle":_vm.MODAL_REJECT_REQUEST_POIN.subtitle,"message":_vm.MODAL_REJECT_REQUEST_POIN.message,"hint":_vm.MODAL_REJECT_REQUEST_POIN.hint,"isDisabled":_vm.controller.isLoading},on:{"close":function () {
        _vm.modalReject = false
      },"reject":_vm.handleEmitReject}}),_c('ModalConfirm',{attrs:{"data-testid":"adjustment-approving-parcel-poin-detail__modal-confirm","visible":_vm.modalConfirm,"isDisabled":_vm.controller.isLoading,"title":_vm.MODAL_CONFIRM_REQUEST_POIN.title,"subtitle":_vm.MODAL_CONFIRM_REQUEST_POIN.description,"phoneNumber":_vm.phoneNumberUser,"poin":_vm.form.poin,"note":_vm.form.note,"textAction":_vm.controller.isLoading ? 'Loading' : 'Yes, Approve',"transaction":_vm.form.transaction},on:{"cancel":function () {
        _vm.modalConfirm = false
      },"action":_vm.handleEmitApprove}}),_c('Modal',{attrs:{"data-testid":"adjustment-approving-parcel-poin-detail__modal-success","visible":_vm.modalSuccess.visible,"type":"success","modalContainer":"modal-container-md","title":_vm.modalSuccess.title,"body":_vm.modalSuccess.subtitle,"textButton":"Okay"},on:{"change":_vm.onSuccess}}),_c('ModalDetail',{attrs:{"data-testid":"adjustment-approving-parcel-poin-detail__modal-detail","visible":_vm.modalDetail,"notes":_vm.currentNotes},on:{"cancel":function () {
        _vm.modalDetail = false
      }}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"field-label"},[_c('span',{staticClass:"label"},[_vm._v("Transaksi")])])}]

export { render, staticRenderFns }