
























































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import AdjustmentTextInput from '../../components/AdjustmentTextInput/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import {
  EnumTransactionParcelPoinRequest,
  REQUEST_TOP_SALDO_PAGINATION,
  Utils,
  MODAL_REJECT_REQUEST_POIN,
  MODAL_CONFIRM_REQUEST_POIN,
  EnumTypeBalanceStatus,
  EventBusConstants,
  MODAL_SUCCESS_APPROVE_POIN,
  MODAL_SUCCESS_REJECT_POIN,
} from '@/app/infrastructures/misc'
import RadioButton from '@/app/ui/components/RadioButton/index.vue'
import { DataObject } from '@/app/ui/components/DataTableV2/type'
import DataTableV2 from '@/app/ui/components/DataTableV2/index.vue'
import PaginationNav from '@/app/ui/components/PaginationNav/index.vue'
import ModalReject from '../../components/AdjustmentModals/ModalReject/index.vue'
import ModalConfirm from '../../components/AdjustmentModals/ModalConfirm/index.vue'
import Modal from '@/app/ui/components/Modal/index.vue'
import ModalDetail from '../../components/AdjustmentModals/ModalDetail/index.vue'
import controller, {
  AdjustmentParcelPoinApprovingState,
  HistoryLogUserParamsInterface,
} from '@/app/ui/controllers/AdjustmentParcelPoinController'
import { Validations } from 'vuelidate-property-decorators'
import {
  maxLength,
  maxValue,
  minLength,
  minValue,
  required,
  integer,
  ValidationRule,
} from 'vuelidate/lib/validators'
import {
  MAX_NOMINAL_REQUEST_POIN,
  MAX_NOTES_REQUEST_TOPUP,
  MIN_NOMINAL_REQUEST_POIN,
} from '@/app/infrastructures/misc/Constants/validation'
import { Dictionary } from 'vue-router/types/router'
import { validationMixin } from 'vuelidate'

/**
 * interface for form state
 */
export interface formPoin {
  poin: number | null
  note: string
  transaction: EnumTransactionParcelPoinRequest
}

/**
 * interface for modal success
 */
export interface modalSuccessInterface {
  visible: boolean
  title: string
  subtitle: string
}

export interface ValidationsInterface {
  form: {
    poin: {
      required: () => ValidationRule
      minValue: ValidationRule
      maxValue: ValidationRule
      integer: () => ValidationRule
    },
    note: {
      required: () => ValidationRule
      minLength: ValidationRule
      maxLength: ValidationRule
    },
    transaction: {
      required: () => ValidationRule
    }
  }
}

@Component({
  mixins: [validationMixin],
  components: {
    LoadingOverlay,
    AdjustmentTextInput,
    Button,
    RadioButton,
    DataTableV2,
    PaginationNav,
    ModalReject,
    ModalConfirm,
    Modal,
    ModalDetail,
  },
})
export default class DetailApprovingPoin extends Vue {
  form: formPoin = {
    poin: null,
    note: '',
    transaction: EnumTransactionParcelPoinRequest.CREDIT,
  }
  EnumTransactionParcelPoinRequest = EnumTransactionParcelPoinRequest
  isShowTable = false
  headers: Array<Record<string, string | Record<string, string>>> = [
    {
      title: 'Date',
      customStyle: { minWidth: '220px', maxWidth: '220px' },
    },
    {
      title: 'Request Poin',
      customStyle: { minWidth: '125px', maxWidth: '125px' },
    },
    {
      title: 'Requestor',
      customStyle: { minWidth: '140px', maxWidth: '140px' },
    },
    {
      title: 'Approval',
      customStyle: { minWidth: '140px', maxWidth: '140px' },
    },
    {
      title: 'Notes',
      customStyle: { minWidth: '155px', maxWidth: '155px' },
    },
    {
      title: 'Status',
      customStyle: { minWidth: '110px', maxWidth: '110px' },
    },
  ]
  parameters = {
    page: 1,
    perPage: REQUEST_TOP_SALDO_PAGINATION,
    customerId: 0,
    sortBy: 'NEWEST',
  }
  modalReject = false
  modalConfirm = false
  modalSuccess: modalSuccessInterface = {
    visible: false,
    title: '',
    subtitle: '',
  }
  modalDetail = false
  MODAL_REJECT_REQUEST_POIN = MODAL_REJECT_REQUEST_POIN
  MODAL_CONFIRM_REQUEST_POIN = MODAL_CONFIRM_REQUEST_POIN
  currentNotes = ''
  controller = controller
  isSuperAdmin = Utils.isSuperAdmin()

  @Validations()
  validations(): ValidationsInterface {
    return {
      form: {
        poin: {
          required,
          minValue: minValue(MIN_NOMINAL_REQUEST_POIN),
          maxValue: maxValue(MAX_NOMINAL_REQUEST_POIN),
          integer,
        },
        note: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(MAX_NOTES_REQUEST_TOPUP),
        },
        transaction: {
          required,
        },
      },
    }
  }

  get dataHistoryUser(): Array<DataObject[]> {
    if (this.controller.listHistoryLogPoinUser.length > 0) {
      return this.controller.listHistoryLogPoinUser.map(item => {
        return [
          {
            value: item.updatedAt ? this.getDate(item.updatedAt) : item.createdAt ? this.getDate(item.createdAt) : '',
            customStyle: { minWidth: '220px', maxWidth: '220px' },
          },
          {
            value: `${
              Number(item.delta) > 0
                ? `+${Utils.currencyDigit(Number(item.delta))}`
                : Utils.currencyDigit(Number(item.delta))
            }`,
            customStyle: { minWidth: '140px', maxWidth: '140px' },
          },
          {
            value: item.requester || '-',
            customStyle: { minWidth: '140px', maxWidth: '140px' },
          },
          {
            value: item.approval || '-',
            customStyle: { minWidth: '140px', maxWidth: '140px' },
          },
          {
            value: item.note,
            customStyle: { minWidth: '155px', maxWidth: '155px' },
          },
          {
            value: Utils.toCapitalize(item.status || '-'),
            customStyle: { minWidth: '110px', maxWidth: '110px' },
          },
        ]
      })
    }

    return []
  }

  private getDate(date: string): string {
    return Utils.formatTimeZone(
      Utils.formatDate(date, 'DD MMM YYYY, HH:mm:ss Z')
    )
  }

  @Watch('controller.statusApproveRejectPoin')
  onStatusApproveRejectPoinChanged(status: string): void {
    switch (status) {
      case EventBusConstants.ADJUSTMENT_PARCEL_POIN_APPROVING_APPROVE_SUCCESS:
        this.modalConfirm = false
        this.modalSuccess = {
          visible: true,
          title: MODAL_SUCCESS_APPROVE_POIN.title,
          subtitle: MODAL_SUCCESS_APPROVE_POIN.subtitle,
        }
        break;
      case EventBusConstants.ADJUSTMENT_PARCEL_POIN_APPROVING_REJECT_SUCCESS:
        this.modalReject = false
        this.modalSuccess = {
          visible: true,
          title: MODAL_SUCCESS_REJECT_POIN.title,
          subtitle: MODAL_SUCCESS_REJECT_POIN.subtitle,
        }
        break;
      case EventBusConstants.ADJUSTMENT_PARCEL_POIN_APPROVING_APPROVE_FAILED:
        this.modalConfirm = false
        break;
      case EventBusConstants.ADJUSTMENT_PARCEL_POIN_APPROVING_REJECT_FAILED:
        this.modalReject = false
        break;
      default:
        break;
    }
    this.controller.setStatusApproveRejectPoin('')
  }

  created(): void {
    // fetch detail
    this.fetchDetail()

    // fetch history
    this.fetchHistory()
  }

  /**
   * get phone number for history log poin user
   */
  get phoneNumberUser(): string {

    if (this.controller.detailAdjustmentParcelPoin.phoneNumber) {
      return Utils.countryIndonesiaPhoneNumber(this.controller.detailAdjustmentParcelPoin.phoneNumber, true)
    } else if (this.controller.listHistoryLogPoinUser[0]?.phoneNumber) {
      return Utils.countryIndonesiaPhoneNumber(this.controller.listHistoryLogPoinUser[0]?.phoneNumber, true)
    }

    return '-'
  }

  /**
   * function toggle isShowTable
   */
  private toggleTableShow(): void {
    this.isShowTable = !this.isShowTable
  }

  /**
   * function to reject adjustment parcel poin
   */
  private handleEmitReject(notes: string): void {
    const payload: AdjustmentParcelPoinApprovingState = {
      pointId: Number(this.$route.params.id.split('-')[1]),
      pointStatus: EnumTypeBalanceStatus.REJECTED,
      reasonNote: notes,
    }
    this.controller.postApprovingPoin(payload)
  }

  /**
   * function to approve adjustment parcel poin
   */
  private handleEmitApprove(): void {
    const payload: AdjustmentParcelPoinApprovingState = {
      pointId: Number(this.$route.params.id.split('-')[1]),
      pointStatus: EnumTypeBalanceStatus.APPROVED,
    }
    this.controller.postApprovingPoin(payload)
  }

  /**
   * function when success reject or approve
   */
  onSuccess(): void {
    this.modalSuccess = {
      visible: false,
      title: '',
      subtitle: '',
    }
    this.$router.push({ name: 'AdjustmentParcelPoinApprovingPoinPage' })
  }

  /**
   * function to see note when click view detail
   */
  private seeNotes(index: number): void {
    this.modalDetail = true
    this.currentNotes = <string>this.dataHistoryUser[index][4].value
  }

  /**
   * get detail adjustment
   */
  private fetchDetail(): void {
    this.controller.getDetailAdjustmentParcelPoin(
      Number(this.$route.params.id.split('-')[1])
    )
  }

  get params(): HistoryLogUserParamsInterface {
    return {
      ...this.parameters,
      customerId: Number(this.$route.params.id.split('-')[0]),
    }
  }

  @Watch('params')
  onParamsChanged(val: Dictionary<string | (string | null)[] | null | undefined> | undefined): void {
    if (!this.isSuperAdmin) {
      this.$router.push({ name: 'AdjustmentParcelPoinApprovingPoinPage' })
    }
    this.$router
      .replace({
        query: {
          ...val,
        },
      })
      .catch(() => false)
  }

  /**
   * when detail from controller changed, change form
   */
  @Watch('controller.detailAdjustmentParcelPoin', { deep: true })
  onChangedForm(): void {
    const poin = this.controller.detailAdjustmentParcelPoin.delta || 0
    const transactionStatus =
      poin > 0
        ? EnumTransactionParcelPoinRequest.CREDIT
        : EnumTransactionParcelPoinRequest.DEBIT

    this.form = {
      poin: this.controller.detailAdjustmentParcelPoin.delta || null,
      transaction: transactionStatus,
      note: this.controller.detailAdjustmentParcelPoin.note || '',
    }
  }

  /**
   * fetch history adjustment
   */
  private fetchHistory(): void {
    this.controller.getHistoryLogPoinUser(this.params)
  }

  beforeDestroy(): void {
    this.controller.setStatusApproveRejectPoin('')
  }
}
